#root {
  height: 100vh;
  max-height: 100vh;
}

.App {
  text-align: center;
  background-color: #a67f8e;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.navbar {
  height: 5%;
  min-height: 3em;
  background-color: #2c1a1d;
}

.navbar-brand,
.nav-link,
.profile-link,
.room-name-text,
.send-message-button {
  color: #dbb3b1;
}

.nav-link:focus {
  color: #dbb3b1;
}

.chat-room-join-title,
.profile-title {
  color: #2c1a1d;
}

.profile-link {
  text-decoration: none;
}

.profile-link:hover {
  color: #000000;
}

.room-history-container,
.chat-members {
  width: 15%;
  background-color: #906e7b;
}

.chat-room,
.chat-room-join {
  height: 100%;
  max-height: 100%;
}

.chat-room-container {
  height: 100%;
  width: 70%;
}

.room-name-container,
.messages-container,
.new-message-field {
  width: 100%;
}

.messages-container {
  height: 100%;
}

.room-name-container,
.new-message-field {
  height: 5.7vh;
  min-height: 5.7vh;
  position: relative;
}

.messages-list {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 83.63vh;
  width: 100%;
  background-color: #a67f8e;
}

.message-timesent {
  font-size: 1rem;
}

.new-message-input-field {
  resize: none;
  padding: 0.5rem;
  margin: 0.5rem;
  width: 100%;
  border-radius: 10px;
  background-color: #dbb3b1;
  overflow: hidden;
}

.password-input-field,
.username-input-field,
.text-input-field {
  background-color: #dbb3b1;
  border-color: #c09d9b;
  border-radius: 5px;
}

.new-message-input-field:focus,
.password-input-field:focus,
.username-input-field:focus,
.text-input-field {
  outline: none;
}

.send-message-button {
  position: absolute;
  right: 1rem;
  top: 20%;
  bottom: 20%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #3a2226;
  border-style: solid;
  border-color: #6c4047;
  height: 60%;
}

.standard-button {
  background-color: #3a2226;
  border-color: #6c4047;
  color: #dbb3b1;
}

.standard-button:hover {
  background-color: #2e1b1f;
  border-color: #6c4047;
  color: #dbb3b1;
}
.room-name-container {
  background-color: #3a2226;
}

.message-item,
.room-history-item {
  list-style: none;
  word-wrap: break-word;
}

.online-member-item,
.offline-member-item {
  list-style: none;
}

.message-text {
  white-space: pre-line;
}

.message-item:hover {
  background-color: #906e7b;
  cursor: pointer;
}

.room-history-item:hover {
  background-color: #755964;
  cursor: pointer;
}

.room-history-item:active {
  filter: brightness(80%);
}

.offline-members-list,
.online-members-list,
.room-history-list {
  padding: 0;
}

.received-message {
  /* background-color: #918c8c; */
}

.my-message {
  /* background-color: rgba(192, 154, 95, 0.5); */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* scrollbar width */
::-webkit-scrollbar {
  width: 10px;
}

/* scrollbar Track */
::-webkit-scrollbar-track {
  background: #dbb3b1;
  border-radius: 8px;
}

/* scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: #3a2226;
  border-radius: 8px;
}

/* scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2c1a1d;
}
